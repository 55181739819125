import {
  container,
  main,
  mainRaised,
  textCenter,
  title,
  whiteColor,
} from "assets/jss/material-kit-pro-react.jsx"

const landingPageStyle = (theme) => ({
  bannerContainer: {
    ...container,
    color: whiteColor,
    zIndex: "2",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "650px",
    },
  },
  bannerHeader: {
    fontWeight: "400",
    margin: "0px",
    "@media (max-width: 700px) and (max-height: 325px)": {
      fontSize: "1.25rem",
    },
  },
  bannerInfo: {
    fontWeight: "400",
    margin: "0px",
    "@media (max-width: 700px) and (max-height: 325px)": {
      fontSize: "1rem",
    },
  },
  bannerRow: {
    display: "flex",
    alignItems: "center",
  },
  bannerTitle: {
    ...title,
    display: "flex",
    position: "relative",
    marginBottom: "0px",
    marginTop: "0px",
    minHeight: "32px",
    color: whiteColor,
    textDecoration: "none",
    marginRight: "5px",
    "@media (max-width: 700px) and (max-height: 325px)": {
      fontSize: "2rem",
    },
    "@media (max-width: 480px)": {
      fontSize: "1.65rem",
    },
    "@media (max-width: 374px)": {
      fontSize: "1.4rem",
    },
  },
  chiliDude: {
    "@media (max-width: 850px)": {
      animationDelay: "1000ms !important",
    },
  },
  chiliDudeContainer: {
    position: "absolute",
    top: "-150px",
    height: "150px",
    width: "150px",
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: "1",
    [theme.breakpoints.down("sm")]: {
      top: "-125px",
      width: "125px",
    },
    "@media (max-width: 700px) and (max-height: 325px)": {
      top: "-100px",
      width: "100px",
    },
  },
  date: {
    ...textCenter,
    marginBottom: "1.5rem",
  },
  headerParallax: {
    "@media (max-width: 850px) and (max-height: 450px)": {
      alignItems: "flex-end",
    },
  },
  iconLarge: {
    width: "45px",
    height: "45px",
    top: "3px",
    position: "relative",
    "@media (max-width: 700px) and (max-height: 325px)": {
      width: "35px",
      height: "35px",
    },
  },
  location: {
    ...textCenter,
  },
  main: {
    ...main,
    ...mainRaised,
  },
  message: {
    "@media (max-width: 700px) and (max-height: 325px)": {
      fontSize: "1.15rem",
    },
    "@media (max-height: 374px)": {
      marginTop: "0px",
    },
    "@media (max-width: 374px)": {
      fontSize: "1.25rem",
    },
  },
  tagline: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      flexWrap: "wrap",
    },
  },
  welcomeMessage: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
      ...textCenter,
      "@media (orientation: landscape)": {
        marginBottom: "0px",
      },
      "@media (max-width: 374px)": {
        marginBottom: "0px",
      },
    },
  },
})

export default landingPageStyle
