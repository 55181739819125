import {
  backgroundImage,
  backgroundImageContainer,
  backgroundImageText,
  blackColor,
  container,
  description,
  hexToRgb,
  section,
  sectionDark,
  textCenter,
  title,
  whiteColor
} from "assets/jss/material-kit-pro-react.jsx"

const cookOffStyle = theme => ({
  backgroundImage: {
    ...backgroundImage,
    height: "500px",
    [theme.breakpoints.down("xs")]: {
      height: "700px",
    },
  },
  backgroundImageText,
  cookOffButton: {
    margin: "1rem",
  },
  cookOffDescription: {
    ...description,
    color: whiteColor,
  },
  cookOffItem: {
    ...textCenter,
    "@media (max-width: 599px) and (orientation: landscape)": {
      flexGrow: "0",
      maxWidth: "83.333333%",
      flexBasis: "83.333333%",
    },
    "@media (max-width: 800px) and (orientation: landscape)": {
      flexGrow: "0",
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  cookOffTitle: {
    ...title,
    color: whiteColor,
    marginTop: "0px",
  },
  container,
  section: {
    ...section,
    ...sectionDark,
    ...backgroundImageContainer,
    position: "relative",
    "&:after": {
      position: "absolute",
      zIndex: "1",
      width: "100%",
      height: "100%",
      display: "block",
      left: "0",
      top: "0",
      content: "''",
      backgroundColor: "rgba(" + hexToRgb(blackColor) + ", 0.7)",
    },
  },
});

export default cookOffStyle
