import {
  cardTitle,
  coloredShadow,
  container,
  description,
  textCenter,
  title,
} from "assets/jss/material-kit-pro-react.jsx"

const blogStyle = (theme) => ({
  blogCategory: {
    color: "#e4240ecc",
    display: "flex",
    alignItems: "center",
  },
  blogContainer: {
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
    },
  },
  blogDescription: {
    ...description,
    fontSize: ".85rem",
  },
  blogIcon: {
    fontSize: "1rem",
    marginRight: "3px",
  },
  blogImage: {
    height: "250px",
    borderRadius: "6px",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    "& img": {
      objectFit: "fill !important",
    },
  },
  blogTitle: {
    ...title,
    ...textCenter,
  },
  cardTitle,
  coloredShadow,
  container,
  marginTop0: {
    marginTop: "0",
  },
  section: {
    backgroundPosition: "50%",
    backgroundSize: "cover",
    padding: "70px 0",
  },
})

export default blogStyle
