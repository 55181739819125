// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page styles
import cookOffStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/cookOffStyle.jsx"
// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(cookOffStyle)

export default function SectionCookOff() {
  const { cookOffBackground } = useStaticQuery(graphql`
    query getCookOffImages {
      cookOffBackground: file(
        relativePath: { eq: "backgrounds/salsa-tasting.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <GatsbyImage
        className={classes.backgroundImage}
        image={cookOffBackground.childImageSharp.gatsbyImageData}
        objectPosition={"bottom"}
        alt="Event Photo"
      />
      <div className={classes.backgroundImageText}>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={10} md={8} className={classes.cookOffItem}>
              <h2 className={classes.cookOffTitle}>
                Chili Cook-Off &amp; Salsa Contest
              </h2>
              <h4 className={classes.cookOffTitle}>
                HELP CHOOSE THE PEOPLE’S CHOICE CHILI!
              </h4>
              <p className={classes.cookOffDescription}>
                It wouldn’t be Chilimania without delicious, aromatic chili! You
                don’t have to be a pro to enter — all you need is a passion for
                great chili. Be sure to arrive in time for the public tasting at
                3PM Saturday. Sample as many chilis as you’d like and vote for
                your favorite to win People’s Choice!
              </p>
              <Link to="/chili-cook-off">
                <Button className={classes.cookOffButton} color="primary">
                  About the Cook-Off
                </Button>
              </Link>
              <Link to="/public-tasting">
                <Button className={classes.cookOffButton} color="primary">
                  Public Tasting Info
                </Button>
              </Link>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  )
}
