// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// @material-ui/icons
import ChevronRightIcon from "@material-ui/icons/ChevronRight"
import MusicNoteIcon from "@material-ui/icons/MusicNote"
import MicIcon from "@material-ui/icons/Mic"
import StarsIcon from "@material-ui/icons/Stars"
// page styles
import blogStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/blogStyle.jsx"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// react components for routing our app without refresh
import { graphql, Link, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
// react libraries
import Slide from "react-reveal/Slide"

const useStyles = makeStyles(blogStyle)

export default function SectionBlog() {
  const {
    joshTurnerBlogImage,
    markReitzAwardBlogImage,
    backInBlackBlogImage,
  } = useStaticQuery(graphql`
    query getBlogImages {
      joshTurnerBlogImage: file(
        relativePath: { eq: "lineup/2024/josh-turner.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      markReitzAwardBlogImage: file(
        relativePath: { eq: "blog/mark-reitz-award-2022.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      backInBlackBlogImage: file(
        relativePath: { eq: "lineup/2024/back-in-black.jpeg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <h2 className={classes.blogTitle}>Chilimania News</h2>
        <br />
        <GridContainer className={classes.blogContainer}>
          <GridItem xs={12} sm={8} md={4}>
            <Slide left>
              <Card className={classes.marginTop0} plain>
                <CardHeader plain image>
                  <GatsbyImage
                    image={joshTurnerBlogImage.childImageSharp.gatsbyImageData}
                    className={classes.blogImage}
                    alt="Blog Post 1"
                  />
                </CardHeader>
                <CardBody plain>
                  <h6 className={classes.blogCategory}>
                    <MusicNoteIcon className={classes.blogIcon} />
                    Music
                  </h6>
                  <h4 className={classes.cardTitle}>
                    Josh Turner Headlining Country Edge 2024
                  </h4>
                  <p className={classes.blogDescription}>
                    We are estastic to announce Josh Turner as our Country Edge
                    headliner for 2024. With timeless songs like “Long Black
                    Train” and “Why Don’t We Just Dance,” Josh Turner’s global
                    reach has surpassed 5.8B global streams, 6.5M album sales
                    and almost 12M track sales world - wide, you don't want to
                    miss this!
                  </p>
                  <Link to="/news">
                    <Button color="primary" size="sm">
                      Read More
                      <ChevronRightIcon className={classes.blogIcons} />
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </Slide>
          </GridItem>
          <GridItem xs={12} sm={8} md={4}>
            <Slide bottom>
              <Card className={classes.marginTop0} plain>
                <CardHeader plain image>
                  <GatsbyImage
                    image={backInBlackBlogImage.childImageSharp.gatsbyImageData}
                    className={classes.blogImage}
                    alt="Blog Post 2"
                  />
                </CardHeader>
                <CardBody plain>
                  <h6 className={classes.blogCategory}>
                    <MusicNoteIcon className={classes.blogIcon} />
                    Music
                  </h6>
                  <h4 className={classes.cardTitle}>
                    Presenting Chilimania's 2024 Headliner: Back in Black
                  </h4>
                  <p className={classes.blogDescription}>
                    For Chilimania 2024, we are excited to announce our
                    headliner, Back in Black, an incredible, exciting, high
                    energy reproduction of a concert performance of AC/DC. Back
                    in Black has performed with Cheap Trick, Foghat, Bad
                    Company, Loverboy, Blue Oyster Cult, Grand Funk Railroad,
                    and Ted Nugent.
                  </p>
                  <Link to="/news">
                    <Button color="primary" size="sm">
                      Read More
                      <ChevronRightIcon className={classes.blogIcons} />
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </Slide>
          </GridItem>
          <GridItem xs={12} sm={8} md={4}>
            <Slide right>
              <Card className={classes.marginTop0} plain>
                <CardHeader plain image>
                  <GatsbyImage
                    image={
                      markReitzAwardBlogImage.childImageSharp.gatsbyImageData
                    }
                    className={classes.blogImage}
                    alt="Blog Post 3"
                  />
                </CardHeader>
                <CardBody plain>
                  <h6 className={classes.blogCategory}>
                    <StarsIcon className={classes.blogIcon} />
                    Award
                  </h6>
                  <h4 className={classes.cardTitle}>
                    2022 Mark Reitz Award Recipient: Dan Hinrichs
                  </h4>
                  <p className={classes.blogDescription}>
                    This year's wonderful and well-deserved recipient of the
                    Mark Reitz Award is Dan Hinrichs. Dan has been volunteering
                    at Chilimania for the past four years, but proudly hasn't
                    missed the event in 18 years. Click below to view the full
                    story.
                  </p>
                  <Link to="/news">
                    <Button color="primary" size="sm">
                      Read More
                      <ChevronRightIcon className={classes.blogIcons} />
                    </Button>
                  </Link>
                </CardBody>
              </Card>
            </Slide>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
