// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page images
// page styles
import eventInfoStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/eventInfoStyle.jsx"
// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// gatsby libraries
import { graphql, Link, useStaticQuery } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"

const useStyles = makeStyles(eventInfoStyle)

export default function SectionEventInfo() {
  const { eventInfoBackground } = useStaticQuery(graphql`
    query getEventInfoPhotos {
      eventInfoBackground: file(
        relativePath: { eq: "backgrounds/event-info.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `)
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <GatsbyImage
        className={classes.backgroundImage}
        image={eventInfoBackground.childImageSharp.gatsbyImageData}
        objectPosition={"top"}
        alt="Event Photo"
      />
      <div className={classes.backgroundImageText}>
        <div className={classes.container}>
          <GridContainer justifyContent="center">
            <GridItem xs={12} sm={10} md={8} className={classes.eventInfoItem}>
              <h2 className={classes.eventInfoTitle}>
                Location, Directions &amp; More
              </h2>
              <h4 className={classes.eventInfoTitle}>
                EVERYTHING YOU NEED TO KNOW!
              </h4>
              <p className={classes.eventInfoDescription}>
                Chilimania and related activities take place in downtown
                Edgerton. The festival is held on North Henry Street, with the
                main gate located at the intersection of West Fulton and North
                Henry. Separate admission is required for Country Edge (Friday
                night) and Chilimania (all day Saturday).
              </p>
              <Link to="/faq">
                <Button className={classes.eventInfoButton} color="primary">
                  View FAQ
                </Button>
              </Link>
              <AnchorLink to="/admissions#admissionDirections">
                <Button className={classes.eventInfoButton} color="primary">
                  Directions
                </Button>
              </AnchorLink>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  )
}
