// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles"
// page images
import infoIconBackground from "assets/img/information.svg"
// page styles
import aboutStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/aboutStyle.jsx"
// core components
import Button from "components/CustomButtons/Button.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
// react components for routing our app without refresh
import { Link } from "gatsby"
import React from "react"

const useStyles = makeStyles(aboutStyle)

export default function SectionAbout() {
  const classes = useStyles()
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justifyContent="center">
          <img
            className={classes.aboutIcon}
            src={infoIconBackground}
            alt="Information Icon"
          />
          <GridItem xs={12} sm={10} md={8} className={classes.aboutItem}>
            <h2 className={classes.aboutTitle}>Who We Are</h2>
            <p className={classes.description}>
              Held annually during the weekend after Labor Day in Edgerton,
              Wisconsin, Chilimania is home to the Wisconsin State Championship
              CASI-Sanctioned Chili Cook-Off, a Traditional Chili Cooking
              Contest, and a Salsa Contest. The weekend-long event also features
              live music on Friday night and all-day Saturday, a Best Bloody
              Mary contest, and more!
              <br />
              <br />
              You’re invited to taste some of these delicious concoctions and
              help choose the People’s Choice! Arrive in time for the Public
              Chili Tasting at 3 PM Saturday, grab a cup, spoon, and ballot, and
              get tasting!
              <br />
              <br />
              Chilimania is 501(c)3 organization whose mission is to promote the
              Edgerton business community, social responsibility, and to raise
              funds to support the education of our youth. We’ve raised more
              than $200,000 since 1990!
            </p>
            <Link to="/about">
              <Button className={classes.aboutButton} color="primary">
                More Info
              </Button>
            </Link>
            <Link to="/register">
              <Button className={classes.aboutButton} color="primary">
                Register
              </Button>
            </Link>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  )
}
