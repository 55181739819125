import {
  container,
  description,
  title,
} from "assets/jss/material-kit-pro-react.jsx"

const aboutStyle = (theme) => ({
  aboutButton: {
    margin: "1rem",
    lineHeight: "18px",
  },
  aboutIcon: {
    position: "absolute",
    opacity: ".25",
    right: "10%",
    maxHeight: "471px",
    maxWidth: "100%",
    [theme.breakpoints.down("sm")]: {
      right: "auto",
      maxWidth: "92%",
      opacity: ".15",
    },
  },
  aboutItem: {
    "@media (max-width: 599px) and (orientation: landscape)": {
      flexGrow: "0",
      maxWidth: "83.333333%",
      flexBasis: "83.333333%",
    },
    "@media (max-width: 800px) and (orientation: landscape)": {
      flexGrow: "0",
      maxWidth: "100%",
      flexBasis: "100%",
    },
  },
  aboutTitle: {
    ...title,
    marginBottom: "1rem",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none",
  },
  container,
  description,
  section: {
    padding: "70px 0",
    textAlign: "center",
  },
})

export default aboutStyle
